<template>
  <div id="enroll">
    <b-card>
      <b-row>
        <b-col>
          <label>HN</label>
          <b-form-input
            v-model="keyword_hn"
            autocomplete="off"
            @keydown="checkEnter"
          />
        </b-col>
        <b-col>
          <label>First name</label>
          <b-form-input
            v-model="keyword_firstName"
            autocomplete="off"
            @keydown="checkEnter"
          />
        </b-col>
        <b-col>
          <label>Last Name</label>
          <b-form-input
            v-model="keyword_lastName"
            autocomplete="off"
            @keydown="checkEnter"
          />
        </b-col>
        <b-col>
          <label>IDNO</label>
          <b-form-input
            v-model="keyword_idno"
            autocomplete="off"
            @keydown="checkEnter"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col class="text-left">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning"
            size="sm"
            @click="clearFormSearch"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span class="align-middle">Clear</span>
          </b-button>
        </b-col>
        <b-col class="text-right">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            size="sm"
            @click="searchPatient"
          >
            <feather-icon
              icon="SearchIcon"
              class="mr-50"
            />
            <span class="align-middle">Search</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="patientLists.length > 0"
        class="mt-1"
      >
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :fields="visibleFields"
            :items="patientLists"
            @row-clicked="showEnrollForm"
          />
        </b-col>
      </b-row>
    </b-card>
    <!-- Modal Treatment Enroll -->
    <b-modal
      id="formCreateEnroll"
      hide-footer
      centered
      size="lg"
      title="Create Enroll"
      no-close-on-backdrop
      modal-class="modal-primary"
    >
      <form-create-enroll
        :hn="hn"
        @exit="onCloseModal"
      />
    </b-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import FormCreateEnroll from './FormCreateEnroll.vue'

export default {
  components: {
    FormCreateEnroll,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      hn: '',
      keyword_hn: '',
      keyword_firstName: '',
      keyword_lastName: '',
      keyword_idno: '',
      patientLists: [],
      patientInfo: [],
      fields: [
        { key: 'hn', label: 'HN', visible: true },
        { key: 'name', label: 'Name', visible: true },
        { key: 'idno', label: 'IDNO', visible: true },
        { key: 'gender', label: 'Gender', visible: true },
        { key: 'phone', label: 'Phone', visible: true },
        { key: 'info', visible: false },
      ],
    }
  },
  computed: {
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.items.length
  },
  methods: {
    onCloseModal(id) {
      this.$bvModal.hide(id)
    },
    checkEnter(key) {
      // // console.log(key.keyCode)
      if (key.keyCode === 13) {
        this.searchPatient()
      }
    },
    searchPatient() {
      this.patientLists = []
      if (this.keyword_hn !== '' || (this.keyword_firstName !== '' && this.keyword_lastName !== '') || this.keywordidno !== '') {
        if (this.keyword_hn !== '') {
          this.keyword_hn = `000000${this.keyword_hn}`.substr(-7)
          this.hn = this.keyword_hn
        }
        this.$http({
          // url: '/api/Patient/patientdetail',
          url: 'http://192.168.100.147/api/Patient/patientdetail',
          method: 'POST',
          data: {
            HN: this.keyword_hn,
            FName: this.keyword_firstName,
            LName: this.keyword_lastName,
            IDCard: this.keyword_idno,
          },
        }).then(({ data }) => {
          for (let i = 0; i < data.length; i += 1) {
            const patientDetail = data[i]
            this.patientLists.push({
              hn: patientDetail.hn,
              name: `${patientDetail.title}${patientDetail.fName} ${patientDetail.mName} ${patientDetail.lName}`,
              dob: patientDetail.dob.substr(0, 10),
              idno: patientDetail.idCard,
              gender: patientDetail.sex,
              phone: patientDetail.phone,
              info: patientDetail,
            })
          }
          // this.patientLists
        }).catch(error => {
          console.log('searchPatient', error)
        })
      }
    },
    showEnrollForm(record, index) {
      console.log(record, index)
      this.$bvModal.show('formCreateEnroll')
    },
    onEnrollCreated(enrolled, id) {
      console.log(enrolled, id)
      if (enrolled) {
        this.$bvModal.hide('treatmentEnrollModal')
      }
    },
    clearFormSearch() {
      this.keyword_hn = ''
      this.keyword_firstName = ''
      this.keyword_lastName = ''
      this.keyword_idno = ''
      this.patientLists = []
    },
  },
}
</script>
