<template>
  <div id="treatment-enroll">
    <b-row>
      <b-col
        cols="2"
      >
        <b-img
          thumbnail
          fluid
          :src="picture"
        />
      </b-col>
      <b-col>
        <b-alert
          variant="primary"
          show
        >
          <div class="alert-body">
            <span>
              <strong>{{ hn }} | {{ fullName }}</strong><br>
              <small>{{ age }} | Born {{ dob }}</small><br>
              <small>Tel. {{ phone }}</small>
            </span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <small class="text-info">List FollowUp</small>
        <b-list-group
          v-for="(fu, index) in followUpLists"
          :key="index"
        >
          <b-list-group-item
            v-if="fu.ok<8&&fu.ok!==2"
            button
            @click="setDataFromFollowUp(fu)"
          >
            <small><b>Time:</b> {{ fu.time }} | <b>Clinic:</b> {{ fu.clinic }} | <b>Doctor:</b> {{ fu.doctor }}</small>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <label>Clinic</label>
        <v-select
          v-model="clinic"
          :options="clinicOptions"
          class="w-100"
          :reduce="name => name.code"
          label="name"
          placeholder="Clinic"
          @input="getDoctorByClinic"
        />
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <label for="doctor">Doctor</label>
        <v-select
          v-model="doctor"
          :options="doctorOptions"
          class="w-100"
          :disabled="clinic === ''"
          :reduce="name => name.code"
          label="name"
          placeholder="Doctor"
          @input="getSchedulePeriod"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label for="treatmentDate">Period</label>
        <v-select
          v-model="period"
          :options="periodOptions"
          class="w-100"
          label="name"
          placeholder="select period"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <label for="payor">Payor</label>
        <v-select
          v-model="payor"
          :options="payorOptions"
          :reduce="name => name.id"
          label="name"
          @input="getPlanByPayorID"
        />
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <label for="plan">Plan</label>
        <v-select
          v-model="plan"
          :options="planOptions"
          :reduce="name => name.id"
          label="name"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
      >
        <label for="service">Remark</label>
        <b-form-textarea
          id="remark"
          v-model="remark"
          rows="3"
        />
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          class="mr-1"
          size="sm"
          @click="clearFormCreate"
        >
          Reset
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="primary"
          size="sm"
          @click="enroll"
        >
          Register
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const curYear = new Date().getFullYear()
const curMonth = `0${(new Date().getMonth() + 1)}`.substr(-2)
const curDay = `0${(new Date().getDate())}`.substr(-2)
const curDayName = days[new Date().getDay()]
const curdate = `${curYear}-${curMonth}-${curDay}`

export default {
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    hn: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      planOpdType: 0,
      // curdate: new Date(),
      picture: '',
      fullName: '',
      nickName: '',
      dob: '',
      age: '',
      IDNO: '',
      gender: '',
      phone: '',
      email: '',
      nationality: '',
      religion: '',
      marital: '',
      payorID: [],
      detailsPatient: '',
      clinic: '',
      doctor: '',
      treatmentDate: null,
      payor: '',
      plan: '',
      service: '',
      remark: '',
      clinicOptions: [],
      doctorOptions: [],
      payorOptions: [],
      planOptions: [],
      serviceOptions: [],
      period: '',
      periodOptions: [],
      doctorCodeID: '',
      followUpLists: [],
    }
  },
  created() {
    this.getPatientInfo()
    this.getClinic()
  },
  methods: {
    getDoctorCodeIDByCode() {
      // // console.log(this.doctorCodeIDLists)
      for (let i = 0; i < this.doctorCodeIDLists.length; i += 1) {
        if (this.doctorCodeIDLists[i].code === this.doctor) {
          return this.doctorCodeIDLists[i].id
        }
      }
      return ''
    },
    getClinic() {
      this.$http({
        // url: '/api/ClinicDoctor/getClinic',
        url: 'http://192.168.100.147/api/ClinicDoctor/getClinic',
        method: 'GET',
        data: {},
      }).then(({ data }) => {
        const result = []
        for (let i = 0; i < data.length; i += 1) {
          const clinicname = `${data[i].clinicCode} - ${data[i].clinicName}`
          result.push({ id: i + 1, code: data[i].clinicCode, name: clinicname })
        }
        this.clinicOptions = result
      }).catch(error => {
        console.log(error)
      })
    },
    getDoctorByClinic() {
      this.doctor = null
      this.isScheduleHeaderActive = false
      this.isScheduleMenuActive = false
      this.$http({
        // url: `/api/Doctor/All/${this.clinic}`,
        url: `http://192.168.100.147/api/Doctor/All/${this.clinic}`,
        method: 'GET',
        data: {},
      }).then(({ data }) => {
        const result = []
        const result2 = []
        for (let i = 0; i < data.length; i += 1) {
          const docname = `${data[i].docCode} - ${data[i].docName}`
          result.push({ id: i + 1, code: data[i].docCode, name: docname })
          result2.push({ id: data[i].doctorCodeID, code: data[i].docCode })
        }
        this.doctorOptions = result
        this.doctorCodeIDLists = result2
        // // console.log(result2)
      }).catch(error => {
        console.log(error)
      })
    },
    getSchedulePeriod() {
      this.periodOptions = []
      this.period = ''
      this.doctorCodeID = ''
      this.$http({
        url: 'http://api159.sriphat.com/api/DoctorSchedule/GetDoctorCodeID',
        method: 'POST',
        data: {
          DocCode: this.doctor,
          ClinicCode: this.clinic,
        },
      }).then(({ data }) => {
        this.$http({
          url: '/api/DoctorSchedule/DailyDoctorSchedule',
          method: 'POST',
          data: {
            DoctorCodeID: data,
            Day: curDayName,
            DateFilter: curdate,
          },
        }).then(({ data2 }) => {
          // this.patientLists = data
          this.baseSchedule = []
          const dataSchedule = data2
          for (let s = 0; s < dataSchedule.scheduleDetails.length; s += 1) {
            if (dataSchedule.scheduleDetails[s].minPerPatient > 0) {
              // // console.log(dataSchedule.scheduleDetails[s].scheduleDetail)
              this.baseSchedule.push(dataSchedule.scheduleDetails[s].scheduleDetail)
              for (let p = 0; p < dataSchedule.scheduleDetails[s].listTimeOfSchedule.length; p += 1) {
                this.periodOptions.push({
                  id: dataSchedule.scheduleDetails[s].listTimeOfSchedule[p],
                  name: dataSchedule.scheduleDetails[s].listTimeOfSchedule[p].substr(0, 5),
                })
              }
            }
          }
        }).catch(error => {
          console.log('getSchedulePeriod', error)
        })
      }).catch(error => {
        console.log('getDoctorCodeID', error)
      })
    },
    getPlanByPayorID() {
      this.planOptions = []
      this.plan = ''
      this.$http({
        url: `api/Patient/PlanByPayorID/${this.payor}`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        for (let i = 0; i < data.length; i += 1) {
          if (data[i].auxiT_STATUS === this.planOpdType) {
            this.planOptions.push({
              id: data[i].auxiT_RowId,
              code: data[i].auxiT_Code,
              name: data[i].auxiT_Desc,
            })
          }
        }
      }).catch(error => {
        console.log('getPlanByPayorID', error)
      })
    },
    getPatientInfo() {
      this.$http({
        // url: '/api/Patient/patientdetail',
        url: 'http://192.168.100.147/api/Patient/patientdetail',
        method: 'POST',
        data: {
          HN: this.hn,
          FName: '',
          LName: '',
          IDCard: '',
        },
      }).then(({ data }) => {
        // // console.log(data)
        if (data.length > 0) {
          const patientDetail = data[0]
          this.fullName = `${patientDetail.title}${patientDetail.fName} ${patientDetail.mName} ${patientDetail.lName}`
          this.hn = patientDetail.hn
          this.dob = patientDetail.dob.substr(0, 10)
          this.age = patientDetail.age
          this.phone = patientDetail.phone
          this.payorOptions = []
          const tempPayorCheck = []
          const tempPayor = []
          for (let p = 0; p < data[0].historyOfPayorPlan.length; p += 1) {
            // // console.log(data[0].historyOfPayorPlan[p].payorCode, this.payorOptions.includes(data[0].historyOfPayorPlan[p].payorCode), this.payorOptions)
            if (tempPayorCheck.includes(data[0].historyOfPayorPlan[p].payorCode) === false) {
              tempPayorCheck.push(data[0].historyOfPayorPlan[p].payorCode)
              tempPayor.push({
                id: data[0].historyOfPayorPlan[p].payorID,
                code: data[0].historyOfPayorPlan[p].payorCode,
                name: data[0].historyOfPayorPlan[p].payorName,
              })
            }
          }
          this.payorOptions = tempPayor.sort((a, b) => a.code - b.code)
          this.getFollowCurDate()
          // // console.log(this.payorOptions)
        }
      }).catch(error => {
        console.log('searchPatient', error)
      })
    },
    getFollowCurDate() {
      this.followUpLists = []
      this.$http({
        // url: '/api/Appointment/OldAppointmentByFilter',
        url: 'http://192.168.100.56/api/Appointment/OldAppointmentByFilter',
        method: 'POST',
        data: {
          HN: this.hn,
          DateApp: curdate,
          DOC: '',
        },
      }).then(({ data }) => {
        // // console.log(data)
        for (let i = 0; i < data.length; i += 1) {
          this.followUpLists.push({
            id: i,
            clinic: data[i].room,
            doctor: data[i].doc,
            time: `${data[i].time}-${data[i].timE1}`,
            remark: data[i].comeForText,
            ok: data[i].ok,
          })
        }
      }).catch(error => {
        console.log('searchPatient', error)
      })
    },
    setDataFromFollowUp(arg) {
      // console.log(arg)
      this.clinic = arg.clinic
      this.getDoctorByClinic()
      this.doctor = arg.doctor
      this.remark = arg.remark
    },
    clearFormCreate() {
      this.clinic = ''
      this.doctor = ''
      this.period = ''
      this.periodOptions = []
      this.remark = ''
      this.payor = ''
      this.plan = ''
    },
    createEnroll(e) {
      e.preventDefault()
      // if (this.daySelected.length === 0) {
      //   return
      // }
      // if (this.amountOfPatient <= 0) {
      //   return
      // }

      this.$http({
        url: '/api/Enroll/NewEnroll',
        method: 'POST',
        data: {
          Service_Event: {
            ComeFor: 0,
            DoctorCodeID: 0,
            ClinicID: 0,
            PayorID: 0,
            PlanID: 0,
            ServiceType: 0,
          },
          ScheduleID: 0,
          QueueNum: '10:00',
          QueueType: 'OPD',
          HN: this.hn,

        },
      }).then(({ data }) => {
        console.log(data)
        this.$swal({
          title: 'Enroll Created.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$emit('exit', 'formCreateAppointmentModal')
          }
        })
      }).catch(error => {
        console.log(error)
      })
    },
    enroll() {
      // console.log('Enrolled')
      this.$swal({
        title: 'Enrollment Sucess.',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$emit('EnrollCreate', 'Enrolled', 'id')
        }
      })
    },
  },
}
</script>

<style>

</style>
